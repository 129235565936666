import React, { Fragment, useEffect, useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { Row, Col, Card, Table } from "react-bootstrap";
import { COLUMNS } from "../../components/table/FilteringTable/Columns";
import MOCK_DATA from "../../components/table/FilteringTable/MOCK_DATA_2.json";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { fireBase, db } from "./Firebase";
import { collection, getDocs } from "firebase/firestore";
import Contest from "./Contest";
import Question from "./Question";
import Papa from "papaparse";
import Csv from "./Csv";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#dadde9",
    fontSize: "12px",
    fontWeight: 400,
    border: "1px solid #25262B",
  },
}));

export const AllUser = () => {
  const [apiData, setApiData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const [adminData, setAdminData] = useState([]);
  const [active, setActive] = useState(1);

  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "adminLogin"));
        const data = querySnapshot.docs.map((doc) => {
          return doc.data();
        });
        console.log(data);
        setAdminData(data);
      } catch (error) {
        console.error("Error fetching adminLogin data:", error);
      }
    };

    fetchAdminData();
  }, []);

  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          console.log(result.data); // Logs the CSV data to console
        },
        header: true, // if you want the first row as headers
      });
    }
  };

  const handleClick = () => {
    document.getElementById("fileInput").click(); // Programmatically click the hidden input
  };

  return (
    <Fragment>
      <Row>
        <div className="display_end" style={{ opacity: "0" }}>
          <div className="input-group" style={{ maxWidth: "300px" }}>
            <input
              type="search"
              id="form1"
              className="form-control"
              placeholder="Search here..."
              // onChange={handleSearch}
            />
          </div>
          <label class="form-label" for="form1"></label>
        </div>

        <Col lg={12}>
          <Card>
            <Card.Header className="qustn-btn">
              <Card.Title
                className={`btn ${active == 1 ? "active1" : ""}`}
                onClick={() => {
                  setActive(1);
                }}
              >
                Add Contest
              </Card.Title>
              <Card.Title
                className={`btn ${active == 2 ? "active1" : ""}`}
                onClick={() => {
                  setActive(2);
                }}
              >
                Add Question
              </Card.Title>
              <Card.Title
                className={`btn ${active == 3 ? "active1" : ""}`}
                onClick={() => {
                  setActive(3);
                }}
              >
                Add CSV
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="row col-lg-12">
                {active == 1 && <Contest />}
                {active == 2 && <Question />}
                {active == 3 && <Csv />}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default AllUser;
